<header *ngIf="mobile" class="mobile">
    <span class="marqueeOuter">
    <span class="marquee" [routerLink]="['']">
        <span class="marqueeInner" aria-hidden="true">
            <span >
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
            </span>
            <span>
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
                <img src="../assets/logo.svg" alt="Uvaga" />
                <img src="../assets/bang.svg" alt="" />
            </span>
        </span>
    </span>
    </span>
    <button (click)="toggleNav()">
        <img src="../assets/burger.svg" alt="Menu" />
    </button>
</header>

<nav id="mobNavigation" *ngIf="mobile">
    <ul>
        <li>
            <a [routerLink]="['we']">We</a>
        </li>
        <li>
            <a [routerLink]="['projects']">Portfolio</a>
        </li>
        <li>
            <a [routerLink]="['vacancy']">Vacancy</a>
        </li>
        <li>
            <a [routerLink]="['contacts']">Contacts</a>
        </li>
    </ul>
</nav>

<button id="closeNav" (click)="toggleNav()" *ngIf="mobile">
    <img src="../assets/closeNav.svg" alt="Close menu" />
</button>

<header *ngIf="desktop" id="desktopNav">
    <span class="marqueeOuter">
        <span class="marquee">
            <span class="marqueeInner" aria-hidden="true">
                <span>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['we']" class="hvr-pop">
        <img src="../assets/teamIcon.svg" alt="We" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['projects']" class="hvr-pop">
        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['vacancy']" class="hvr-pop">
        <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['contacts']" class="hvr-pop">
        <img src="../assets/contactsIcon.svg" alt="Contacts" />
    </a>
   </span>
   <span>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['we']" class="hvr-pop">
        <img src="../assets/teamIcon.svg" alt="We" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['projects']" class="hvr-pop">
        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['vacancy']" class="hvr-pop">
        <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
    </a>
    <a [routerLink]="['']" class="logo hvr-pop">
        <img src="../assets/logo.svg" alt="Uvaga" />
    </a>
    <a [routerLink]="['contacts']" class="hvr-pop">
        <img src="../assets/contactsIcon.svg" alt="Contacts" />
    </a>
   </span>
   </span>
   </span>
   </span>
</header>