<div class="contacts">
    <div class="addressContainer">
        <div class="main">
            UVAGA!<br />COMMUNICATION
        </div>
        <div class="address">
            Kyiv 04050, <br />
Turgenevskaya st. 46/11, <br />
office 104
        </div>
    </div>
    <a class="map" href="https://goo.gl/maps/TkP49AXFzYphJJwu9" target="_blank">
    </a>
    <div class="phoneNumbers">
        <span class="marquee">
            <span class="marqueeInner">
                <span>
                    <a href="tel:+380674959218">+380674959218</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+380671111423">+380671111423</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+38097774550">+38097774550</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+380674959218" class="desktopAdditionals">+380674959218</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals"alt="" />
                    <a href="tel:+380671111423" class="desktopAdditionals">+380671111423</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals" alt="" />
                    <a href="tel:+38097774550" class="desktopAdditionals">+38097774550</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals" alt="" />
                </span>
                <span>
                    <a href="tel:+380674959218">+380674959218</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+380671111423">+380671111423</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+38097774550">+38097774550</a>
                    <img src="../assets/bang.svg" alt="" />
                    <a href="tel:+380674959218" class="desktopAdditionals">+380674959218</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals"alt="" />
                    <a href="tel:+380671111423" class="desktopAdditionals">+380671111423</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals" alt="" />
                    <a href="tel:+38097774550" class="desktopAdditionals">+38097774550</a>
                    <img src="../assets/bang.svg" class="desktopAdditionals" alt="" />
                </span>
            </span>
        </span>
    </div>
</div>