<div class="singleProject" id="singleProject" (scroll)="onScroll($event)">
    <div id="project" style="height: 80%">
    <div id="introSection">
        <div class="title">
            <h1>{{project.name}}</h1>
            <h2>client: <b>{{project.client}} /</b> product: <b>{{project.product}}</b></h2>
        </div>
        <figure id="introFigure">
            <img src="{{project.mainImage}}" />
        </figure>
        <a id="closeProject" [routerLink]="['/projects']">
            <img src="../assets/closeNav.svg" alt="Close menu" />
        </a>
        <span id="leftArrow" *ngIf="showLeftArrow">
            <img src="../assets/arrow.png" alt="" />
        </span>
        <span id="rightArrow" *ngIf="showRightArrow">
            <img src="../assets/arrow.png" alt="" />
        </span>
    </div>
<div [innerHTML]="project.description | safeHtml" class="projectBody"></div>
<figure id="footerImg"><img src="{{project.footerImg}}" /></figure>
</div>

<div class="projectsCatalog" *ngIf="!loading && desktop">
    <span class="marqueeOuter">
        <span class="marquee">
            <span class="marqueeInner" aria-hidden="true">
                <span>
    <a class="project" *ngFor="let project of projects" routerLink="/project/{{project.id}}">
        <div class="imgHolder" [style.backgroundImage]="'url('+project.previewMob+')'"></div>
      </a>
      </span>
      <span>
        <a class="project" *ngFor="let project of projects" routerLink="/project/{{project.id}}">
            <div class="imgHolder" [style.backgroundImage]="'url('+project.previewMob+')'"></div>
          </a>
          </span>
          </span>
          </span>
          </span>
    </div>
</div>

<div *ngIf="showTobaccoAgreement" id="tobaccoDisclaimer">
    <div class="popup">
    <img src="../assets/warningIcon.svg" />
    <p>
      You have to be over 18 to view this page.
    </p>
    <p>
    Are you?
    </p>
    <div class="buttonGroup">
      <button (click)="agree()">Yes</button>
      <button [routerLink]="['/projects']">No</button>
    </div>
    </div>
    </div>