import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectItemComponent } from './components/project-item/project-item.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { TeamComponent } from './components/team/team.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactsComponent } from './components/contacts/contacts.component';

import { LayoutModule } from '@angular/cdk/layout';
import { VacancyComponent } from './components/vacancy/vacancy.component';

import { SafeHtmlPipe } from './safe-html.pipe';
// import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    ProjectItemComponent,
    HomeComponent,
    HeaderComponent,
    TeamComponent,
    ContactsComponent,
    VacancyComponent,
    SafeHtmlPipe,
    // VjsPlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    LayoutModule
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
