import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectItemComponent } from './components/project-item/project-item.component';
import { HomeComponent } from './components/home/home.component';
import { TeamComponent } from './components/team/team.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { VacancyComponent } from './components/vacancy/vacancy.component';


const routes: Routes = [{
  path: '',
  component: ProjectsComponent,
}, {
  path: 'projects',
  component: ProjectsComponent,
},
{
  path: 'project/:id',
  component: ProjectItemComponent,
},
{
  path: 'we',
  component: TeamComponent,
},
{
  path: 'vacancy',
  component: VacancyComponent,
},
{
  path: 'contacts',
  component: ContactsComponent,
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
