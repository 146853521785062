
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BreakpointsService {
  breakpoints: object = {
    '(max-width: 767px)': CustomBreakpointNames.mobile,
    '(min-width: 768px) and (max-width: 768px)': CustomBreakpointNames.tablet,
    '(min-width: 769px)': CustomBreakpointNames.desktop
  };

  getBreakpoints(): string[] {
    return Object.keys(this.breakpoints);
  }

  getBreakpointName(breakpointValue): string {
    return this.breakpoints[breakpointValue];
  }

  constructor() {
  }
}

export const CustomBreakpointNames = {
  mobile: 'mobile',
  desktop: 'desktop',
  tablet: 'tablet'
};
