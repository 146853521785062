<div class="vacancy">
    <div class="main">
        Hey, we <br />always stand <br />for talent and professionalism.<br />
        If you are ready, send us your CV, link or email here:<br /><br />
        <a href="mailto:maria.mizina@uvaga.agency">maria.mizina@uvaga.agency</a>
    </div>
    <div class="services">
        <span class="marquee">
            <span class="marqueeInner">
                <span class="marqueeRepeat">
                    <span>PAckaging</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>Outdoor</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>STRATEGY</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>DESIGN</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>3D</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>TV</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>Animation</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>POSTPRODUCTION</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span class="bigIdea">Big idea</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>CI</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>communication</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>Digital</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>P2p</span>
                    <img src="../assets/bang.svg" alt="" />
                </span>
                <span class="marqueeRepeat">
                    <span>PAckaging</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>Outdoor</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>STRATEGY</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>DESIGN</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>3D</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>TV</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>Animation</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>POSTPRODUCTION</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span class="bigIdea">Big idea</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>CI</span>                    
                    <img src="../assets/bang.svg" alt="" />
                    <span>communication</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>Digital</span>
                    <img src="../assets/bang.svg" alt="" />
                    <span>P2p</span>
                    <img src="../assets/bang.svg" alt="" />
                </span>
            </span>
        </span>
    </div>
    </div>