import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { GlobalConstants } from './common/global-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'uvaga';
  vh: any;
  isHomepage: any;
  isLoading = true;

  constructor(private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          if (!this.router.url.includes('/projects') && document.body.classList.contains('scrollableBody')) {
            document.body.classList.remove('scrollableBody');
          }
          if (!this.router.url.includes('/project/') && document.body.classList.contains('scrollableBody_project')) {
            document.body.classList.remove('scrollableBody_project');
          }
          if (this.router.url.includes('/project') ||
              this.router.url.includes('/we') ||
              this.router.url.includes('/vacancy') ||
              this.router.url.includes('/contact')) {
            this.isHomepage = false;
          } else {
            this.isHomepage = true;
          }
          if (!this.router.url.includes('/project/')) {
            document.body.classList.remove('projectPage');
          }
        }
    });
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    window.addEventListener('resize', () => {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
      if (window.innerWidth > 767 && document.getElementById('desktopNav')) {
          document.getElementById('desktopNav').style.display = 'block';
        }
      if (this.router.url.includes('/projects') && window.innerWidth > 767 && document.getElementById('desktopNav')) {
          const desktopNav = document.getElementById('desktopNav').getBoundingClientRect();
          document.getElementById('catalog').style.paddingTop = desktopNav.height + 'px';
        } else if (this.router.url.includes('/projects') && window.innerWidth < 768) {
          document.getElementById('catalog').style.paddingTop = '';
        }
    });
}

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationEnd) {
      const mobNav =  document.getElementById('mobNavigation');
      if (typeof(mobNav) !== 'undefined' && mobNav !== null) {
        document.getElementById('mobNavigation').classList.remove('showNav');
      }
      const closeNav =  document.getElementById('closeNav');
      if (typeof(closeNav) !== 'undefined' && closeNav !== null) {
        document.getElementById('closeNav').classList.remove('showCloseNav');
      }
    }
  }
}
