import { Component, OnInit, AfterContentChecked, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';
import { Subject } from 'rxjs';
// import 'rxjs/add/operator/debounceTime';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  resize$ = new Subject<void>();
  desktop: any;
  tablet: any;
  mobile: any;
  horizontalMargin: any;

  constructor(private layoutService: LayoutService) {
  }

  ngOnInit(): void {
    // this.resize$.debounceTime(300).subscribe(() => {
    //   console.log('resize...');
    //   console.log('-----');
    // });
    this.resize$.pipe(debounceTime(10))
      .subscribe(x => this.resizeCalc());
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
        this.tablet = false;
      } else if (this.layoutService.isBreakpointActive(CustomBreakpointNames.tablet))  {
        this.desktop = false;
        this.mobile = false;
        this.tablet = true;
      } else {
        this.desktop = true;
        this.mobile = false;
        this.tablet = false;
      }
      });
    };
  ngAfterViewInit(): void {
    this.resizeCalc();
    setTimeout(
      () => {
        document.getElementById('loader').style.display = 'none';
      }, 1000);
  }

  ngOnDestroy(): void {
    document.getElementById('loader').style.display = '';
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resize$.next();
  }

  resizeCalc() {
    if (this.desktop || window.innerWidth === 768 || window.innerWidth > 768) {
      this.horizontalMargin = 64;
    } else {
      this.horizontalMargin = 32;
    }
    const logoArchetype = document.getElementById('logoArchetype').getBoundingClientRect();
    const logoArchetypeWidth = logoArchetype.height * 3.72925601343 + this.horizontalMargin;
    const iconArchetype = document.getElementById('iconArchetype').getBoundingClientRect();
    const iconArchetypeWidth = iconArchetype.height * 1.33331934712 + this.horizontalMargin;
    const marqueeWidth = logoArchetypeWidth * 2 + iconArchetypeWidth * 2 + 14;
    const marquees = document.getElementsByClassName('marquee');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < marquees.length; i++) {
      marquees[i].setAttribute('style', 'width:' + marqueeWidth + 'px');
   }
  }
  pause() {
    document.getElementById('homepageMenu').classList.toggle('pause');
  }
 }
