import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
  desktop: any;
  mobile: any;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
      } else {
        this.desktop = true;
        this.mobile = false;
        document.getElementById('desktopNav').style.display = 'block';
      }
    });
  }

   ngAfterViewInit(): void {
     setTimeout(
      () => {
        document.getElementById('loader').style.display = 'none';
      }, 1000);
  }

  ngOnDestroy(): void {
    document.getElementById('loader').style.display = '';
  }

}
