import { Injectable } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  constructor(private http: HttpClient) { }

  getAllProjects(): Observable<Project[]> {
    return this.http.get<Project[]>('/.netlify/functions/getProjects', {
      headers: {
        'Content-Type': 'application/json'
      },
    });
  }
}
