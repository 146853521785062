import { Component, OnInit } from '@angular/core';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  desktop: any;
  mobile: any;

  constructor(private layoutService: LayoutService, private router: Router) { }

  ngOnInit(): void {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
      } else {
        this.desktop = true;
        this.mobile = false;
      }
    });
  }

  toggleNav() {
    document.getElementById('mobNavigation').classList.toggle('showNav');
    document.getElementById('closeNav').classList.toggle('showCloseNav');
  }

}
