import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, AfterViewInit, OnDestroy {
  desktop: any;
  mobile: any;
  tablet: any;

  teamCarouselMob: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    responsive: {
      0: {
        items: 1
      }
    },
  };

  teamCarouselDesktop: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      }
    },
  };

  teamData = [
    {
      name: 'Ro',
      surname: 'cky',
      position: 'KING OF THE RING VON GROSSEM HERZ',
      phone: '',
      email: '',
      photo_mob: '/assets/rocky.jpg',
      photo_desktop: '/assets/rocky_desktop.png'
    },
    {
      name: 'Maria',
      surname: 'Mizina',
      position: 'CO-FOUNDER / CREATIVE DIRECTOR',
      phone: '+380-974-555-501',
      email: 'maria.mizina@uvaga.agency',
      photo_mob: '/assets/maria.jpg',
      photo_desktop: '/assets/maria_desktop.png'
    },
    {
      name: 'Alexey',
      surname: 'Moroz',
      position: 'CO-FOUNDER / STRATEGIC PLANER',
      phone: '+380-674-959-218',
      email: 'alexey.moroz@uvaga.agency',
      photo_mob: '/assets/alexey.jpg',
      photo_desktop: '/assets/alexey_desktop.png'
    },
    {
      name: 'ALEXANDER',
      surname: 'WILHAUK',
      position: 'CO-FOUNDER / HEAD OF ART',
      phone: '+380-671-111-423',
      email: 'alexander.wilhauk@uvaga.agency',
      photo_mob: '/assets/alexander.jpg',
      photo_desktop: '/assets/alexander_desktop.png'
    }
  ];

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
        this.tablet = false;
      } else if (this.layoutService.isBreakpointActive(CustomBreakpointNames.tablet))  {
        this.desktop = false;
        this.mobile = false;
        this.tablet = true;
      } else {
        this.desktop = true;
        this.mobile = false;
        this.tablet = false;
      }
    if (this.desktop || this.tablet) {
      document.getElementById('desktopNav').style.display = 'block';
    };
  });
  }

  ngAfterViewInit(): void {
     setTimeout(
      () => {
        document.getElementById('loader').style.display = 'none';
      }, 1000);
  }

  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
      if (event.key === 'ArrowLeft') {
        const prev: HTMLElement = document.getElementsByClassName('owl-prev')[0] as HTMLElement;
        prev.click();
      } else if (event.key === 'ArrowRight') {
          const next: HTMLElement = document.getElementsByClassName('owl-next')[0] as HTMLElement;
          next.click();
    }
}

ngOnDestroy(): void {
  document.getElementById('loader').style.display = '';
}

}
