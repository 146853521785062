import { Component, OnInit, Input, ViewEncapsulation, AfterContentChecked, OnDestroy, HostListener } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { Project } from 'src/app/models/Project';
import { Router, ActivatedRoute } from '@angular/router';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';
import * as Plyr from 'plyr';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectItemComponent implements OnInit, AfterContentChecked, OnDestroy {
  projects: Project[] = [];
  loading = false;
  project: any;
  id: any;
  desktop: any;
  mobile: any;
  timer: any;
  showTobaccoAgreement = GlobalConstants.showTobaccoAgreement;
  tobaccoAgreement = GlobalConstants.tobaccoAgreement;
  showLeftArrow = false;
  showRightArrow = true;

  constructor(
      // private elem: ElementRef,
      private projectsService: ProjectsService,
      private layoutService: LayoutService,
      private route: ActivatedRoute) {
    route.params.subscribe(val => {
      window.scrollTo(0, 0);
      const outerThis = this;
      this.id = this.route.snapshot.paramMap.get('id');
      this.getProjects();
    });
  }

  ngOnInit() {
    document.body.classList.add('scrollableBody_project');
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
      } else {
        this.desktop = true;
        this.mobile = false;
        document.body.classList.add('projectPage');
      }
    });
    if (this.desktop) {
      document.getElementById('desktopNav').style.display = 'block';
    }
  }
  ngAfterContentChecked() {
    // const introFigure = document.getElementById('introFigure').getBoundingClientRect();
    // const title = document.getElementById('title').getBoundingClientRect();
    // const introFigureWidth = introFigure.width;
    // const titleWidth = title.width;
    // const width = titleWidth + introFigureWidth;
    // console.log(width);
    // document.getElementById('introSection').setAttribute('style', 'width:' + width + 'px');
  }

  getProject(): void {
    // tslint:disable-next-line:triple-equals
	this.project = this.projects.find(project => project.id == this.id);
	console.log(this.project.ageRestriction);
	console.log(this.tobaccoAgreement);
	const outerThis = this;
	document.getElementById('singleProject').scrollLeft = 0;
 setTimeout(
      () => {
		if (!outerThis.tobaccoAgreement && outerThis.project.ageRestriction) {
			outerThis.showTobaccoAgreement = true;
		  }
		 else {
			outerThis.showTobaccoAgreement = false;
		}
  let videoFiles = document.querySelectorAll('.videoFile');
  for (let i = 0; i < videoFiles.length; i++) {
          console.log(videoFiles[i].id);
          new Plyr('#' + videoFiles[i].id);
        }
  document.getElementById('loader').style.display = 'none';
      }, 1000);
  }

  getProjects(): void {
    this.projectsService.getAllProjects()
    .subscribe(projects => {
      this.loading = false;
      this.projects = projects;
    }, () => {}, () => this.getProject());
  }

  agree() {
    this.showTobaccoAgreement = false;
    this.tobaccoAgreement = true;
  }

  ngOnDestroy(): void {
	document.getElementById('loader').style.display = '';
	GlobalConstants.showTobaccoAgreement = this.showTobaccoAgreement;
	GlobalConstants.tobaccoAgreement = this.tobaccoAgreement;
  }


     @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    if (window.innerWidth > 768) {
    this.handleMousemove(e);
    }
  }

		// --------------------------------------------------------------------------- //
		// --------------------------------------------------------------------------- //

		// I adjust the window scrolling in response to the given mousemove event.
		handleMousemove(event) {


    let edgeSize = 200;

			// Get the viewport-relative coordinates of the mousemove event.
			 let viewportX = event.clientX;

    let project = document.getElementById('project');

			// Get the viewport dimensions.
			 let viewportWidth = document.documentElement.clientWidth;

			// Next, we need to determine if the mouse is within the "edge" of the 
			// viewport, which may require scrolling the window. To do this, we need to
			// calculate the boundaries of the edge in the viewport (these coordinates
			// are relative to the viewport grid system).
			 let edgeLeft = edgeSize;
			 let edgeRight = ( viewportWidth - edgeSize );

			 let isInLeftEdge = ( viewportX < edgeLeft );
			 let isInRightEdge = ( viewportX > edgeRight );

			// If the mouse is not in the viewport edge, there's no need to calculate
			// anything else.
			 if ( ! ( isInLeftEdge || isInRightEdge) ) {
				clearTimeout( this.timer );
				return;
			}

			// If we made it this far, the user's mouse is located within the edge of the
			// viewport. As such, we need to check to see if scrolling needs to be done.

			// Get the document dimensions.
			// --
			// NOTE: The constious property reads here are for cross-browser compatibility
			// as outlined in the JavaScript.info site (link provided above).
			 let documentWidth = Math.max(
				project.offsetWidth
			);

			// Calculate the maximum scroll offset in each direction. Since you can only
			// scroll the overflow portion of the document, the maximum represents the
			// length of the document that is NOT in the viewport.
			 let maxScrollX = ( documentWidth - viewportWidth );

			// As we examine the mousemove event, we want to adjust the window scroll in
			// immediate response to the event; but, we also want to continue adjusting
			// the window scroll if the user rests their mouse in the edge boundary. To
			// do this, we'll invoke the adjustment logic immediately. Then, we'll setup
			// a timer that continues to invoke the adjustment logic while the window can
			// still be scrolled in a particular direction.
			// --
			// NOTE: There are probably better ways to handle the ongoing animation
			// check. But, the point of this demo is really about the math logic, not so
			// much about the interval logic.
    const outerThis = this;
			 function checkForWindowScroll() {

				clearTimeout( outerThis.timer );

				if ( adjustWindowScroll() ) {

					outerThis.timer = setTimeout( checkForWindowScroll, 10 );

				} 

			};
    checkForWindowScroll();

			// Adjust the window scroll based on the user's mouse position. Returns True
			// or False depending on whether or not the window scroll was changed.
			 function adjustWindowScroll() {

				// Get the current scroll position of the document.
				let currentScrollX = document.getElementById('project').getBoundingClientRect().left;
        // console.log(document.getElementById('project').getBoundingClientRect());

				// Determine if the window can be scrolled in any particular direction.
				let canScrollLeft = ( currentScrollX < 0 );
    console.log(canScrollLeft);
				let canScrollRight = ( currentScrollX < maxScrollX );


				// Since we can potentially scroll in two directions at the same time,
				// let's keep track of the next scroll, starting with the current scroll.
				// Each of these values can then be adjusted independently in the logic
				// below.
				let nextScrollX = -currentScrollX;

				// As we examine the mouse position within the edge, we want to make the
				// incremental scroll changes more "intense" the closer that the user
				// gets the viewport edge. As such, we'll calculate the percentage that
				// the user has made it "through the edge" when calculating the delta.
				// Then, that use that percentage to back-off from the "max" step value.
				let maxStep = 10;

				// Should we scroll left?
				if ( isInLeftEdge && canScrollLeft ) {

					let intensity = ( ( edgeLeft - viewportX ) / edgeSize );
					nextScrollX = ( nextScrollX - ( maxStep * intensity ) );

				// Should we scroll right?
				} else if ( isInRightEdge && canScrollRight ) {

          let intensity = ( ( viewportX - edgeRight ) / edgeSize );

					     nextScrollX = ( nextScrollX + ( maxStep * intensity ) );

					// nextScrollX = nextScrollX + 10;
				}

				// Sanitize invalid maximums. An invalid scroll offset won't break the
				// subsequent .scrollTo() call; however, it will make it harder to
				// determine if the .scrollTo() method should have been called in the
				// first place.
				// nextScrollX = Math.max( 0, Math.min( maxScrollX, nextScrollX ) );

				if (
					( nextScrollX !== (-currentScrollX) )
					) {
					document.getElementById('singleProject').scrollLeft = nextScrollX;
          // console.log(nextScrollX);
					return true;

				} else {

					return false;

				}

			}

		}
		onScroll(event: Event) {
			if (this.desktop) {
			const project = document.getElementById('project');
			const footer = document.getElementById('footerImg');
			 const viewportWidth = document.documentElement.clientWidth;
			 const documentWidth = Math.max(
				project.offsetWidth
			);
			const maxScrollX = viewportWidth - 2 * footer.offsetWidth - documentWidth;
			const currentScrollX = document.getElementById('project').getBoundingClientRect().left;
			const canScrollLeft = ( currentScrollX < 0 );
			const canScrollRight = ( currentScrollX > maxScrollX );
			if (canScrollLeft) {
				this.showLeftArrow = true;
			} else {
				this.showLeftArrow = false;
			}
			if (canScrollRight) {
				this.showRightArrow = true;
			} else {
				this.showRightArrow = false;
			}
		}
		  }

}
