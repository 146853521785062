<main class="teamMob" *ngIf="mobile || tablet">
    <owl-carousel-o [options]="teamCarouselMob" id="carousel">
        <ng-template carouselSlide *ngFor="let teamMember of teamData">
          <div class="contacts">
            <span class="name">{{teamMember.name}}</span>
            <span class="surname">{{teamMember.surname}}</span>
            <span class="position">{{teamMember.position}}</span>
            <a class="bone" *ngIf="teamMember.name == 'Ro'" href="https://prom.ua/p400008991-trixie-2750-kostochka.html?&primelead=MS40Nw" target="_blank"><img src="../assets/bone.svg" alt="Rocky’s bone" /></a>
            <a class="phone" *ngIf="teamMember.name != 'Ro'" href="tel:{{teamMember.phone}}">{{teamMember.phone}}</a>
            <a class="email" *ngIf="teamMember.name != 'Ro'" href="mailto:{{teamMember.email}}">{{teamMember.email}}</a>
          </div>
          <div class="portrait">
            <img class="portraitImg" src="{{teamMember.photo_mob}}" />
          </div>
        </ng-template>
      </owl-carousel-o>
</main>

<main class="teamDesktop" *ngIf="desktop">
  <owl-carousel-o [options]="teamCarouselDesktop" id="carousel">
      <ng-template carouselSlide *ngFor="let teamMember of teamData" class="singleSlide">
        <div class="portrait">
          <img class="portraitImg" src="{{teamMember.photo_desktop}}" />
        </div>
        <div class="contacts">
          <span class="name">{{teamMember.name}}</span>
          <span class="surname">{{teamMember.surname}}</span>
          <span class="position">{{teamMember.position}}</span>
          <a class="bone" *ngIf="teamMember.name == 'Ro'" href="https://prom.ua/p400008991-trixie-2750-kostochka.html?&primelead=MS40Nw" target="_blank"><img src="../assets/bone.svg" alt="Rocky’s bone" /></a>
          <a class="phone" *ngIf="teamMember.name != 'Ro'" href="tel:{{teamMember.phone}}">{{teamMember.phone}}</a>
          <a class="email" *ngIf="teamMember.name != 'Ro'" href="mailto:{{teamMember.email}}">{{teamMember.email}}</a>
        </div>
      </ng-template>
    </owl-carousel-o>
</main>