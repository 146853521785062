<div class="homepageMenu" id="homepageMenu">
    <div>
        <span class="marquee" (touchstart)="pause()" *ngIf="desktop">
            <span class="marqueeInner">
                <span>
        <img src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
        <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
            <img src="../assets/teamIcon.svg" alt="We" />
        </a>
        <img src="../assets/logo.svg" alt="Uvaga" />
        <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
            <img src="../assets/teamIcon.svg" alt="We" />
        </a>
        </span>
        <span>
            <img src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
            <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
                <img src="../assets/teamIcon.svg" alt="We" />
            </a>
            <img src="../assets/logo.svg" alt="Uvaga" />
            <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
                <img src="../assets/teamIcon.svg" alt="We" />
            </a>
            </span>
        </span>
        </span>
           <span class="marquee" (touchstart)="pause()" *ngIf="mobile || tablet">
            <span class="marqueeInner">
                <span>
        <img [routerLink]="['we']" src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
        <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
            <img src="../assets/teamIcon.svg" alt="We" />
        </a>
        <img [routerLink]="['we']" src="../assets/logo.svg" alt="Uvaga" />
        <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
            <img src="../assets/teamIcon.svg" alt="We" />
        </a>
        </span>
        <span>
            <img [routerLink]="['we']" src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
            <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
                <img src="../assets/teamIcon.svg" alt="We" />
            </a>
            <img [routerLink]="['we']" src="../assets/logo.svg" alt="Uvaga" />
            <a [routerLink]="['we']" class="hvr-push" id="iconArchetype">
                <img src="../assets/teamIcon.svg" alt="We" />
            </a>
            </span>
        </span>
        </span>
    </div>
    <div> 
        <span class="marquee marquee2" (touchstart)="pause()" *ngIf="desktop">
            <span class="marqueeInner">
            <span>
                <img src="../assets/logo.svg" alt="Uvaga" />
                <a [routerLink]="['projects']" class="hvr-push">
                    <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                </a>
                <img src="../assets/logo.svg" alt="Uvaga" />
                <a [routerLink]="['projects']" class="hvr-push">
                    <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                </a>
                </span>
                <span>
                    <img src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['projects']" class="hvr-push">
                        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                    </a>
                    <img src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['projects']" class="hvr-push">
                        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                    </a>
                    </span>
                    </span>
            </span>
                    <span class="marquee marquee2" (touchstart)="pause()" *ngIf="mobile || tablet">
            <span class="marqueeInner">
            <span>
                <img [routerLink]="['projects']" src="../assets/logo.svg" alt="Uvaga" />
                <a [routerLink]="['projects']" class="hvr-push">
                    <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                </a>
                <img [routerLink]="['projects']" src="../assets/logo.svg" alt="Uvaga" />
                <a [routerLink]="['projects']" class="hvr-push">
                    <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                </a>
                </span>
                <span>
                    <img [routerLink]="['projects']" src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['projects']" class="hvr-push">
                        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                    </a>
                    <img [routerLink]="['projects']" src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['projects']" class="hvr-push">
                        <img src="../assets/portfolioIcon.svg" alt="Portfolio" />
                    </a>
                    </span>
                    </span>
            </span>
    </div>
    <div *ngIf="desktop">
        <span class="marquee marquee3">
            <span class="marqueeInner">
                <span>
                    <img src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['vacancy']" class="hvr-push">
                        <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                    </a>
                    <img src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['contacts']" class="hvr-push">
                        <img src="../assets/contactsIcon.svg" alt="Contacts" />
                    </a>
                    </span>
                    <span>
                        <img src="../assets/logo.svg" alt="Uvaga" />
                        <a [routerLink]="['vacancy']" class="hvr-push">
                            <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                        </a>
                        <img src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
                        <a [routerLink]="['contacts']" class="hvr-push">
                            <img src="../assets/contactsIcon.svg" alt="Contacts" />
                        </a>
                        </span>
        </span>
        </span>
    </div>
    <div *ngIf="mobile || tablet">
        <span class="marquee marquee3" (touchstart)="pause()">
            <span class="marqueeInner">
                <span>
                    <img [routerLink]="['vacancy']" src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['vacancy']" class="hvr-push">
                        <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                    </a>
                    <img [routerLink]="['vacancy']" src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['vacancy']" class="hvr-push">
                        <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                    </a>
                    </span>
                    <span>
                        <img [routerLink]="['vacancy']" src="../assets/logo.svg" alt="Uvaga" />
                        <a [routerLink]="['vacancy']" class="hvr-push">
                            <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                        </a>
                        <img [routerLink]="['vacancy']" src="../assets/logo.svg" alt="Uvaga" />
                        <a [routerLink]="['vacancy']" class="hvr-push">
                            <img src="../assets/vacancyIcon.svg" alt="Vacancy" />
                        </a>
                        </span>
        </span>
        </span>
    </div>
    <div *ngIf="mobile || tablet">
        <span class="marquee marquee4" (touchstart)="pause()">
            <span class="marqueeInner">
                <span>
                    <img [routerLink]="['contacts']"  src="../assets/logo.svg" alt="Uvaga" />
                    <a [routerLink]="['contacts']" class="hvr-push">
                        <img src="../assets/contactsIcon.svg" alt="Contacts" />
                    </a>
                    <img [routerLink]="['contacts']"  src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
                    <a [routerLink]="['contacts']" class="hvr-push">
                        <img src="../assets/contactsIcon.svg" alt="Contacts" />
                    </a>
                    </span> 
                    <span>
                        <img [routerLink]="['contacts']"  src="../assets/logo.svg" alt="Uvaga" />
                        <a [routerLink]="['contacts']" class="hvr-push">
                            <img src="../assets/contactsIcon.svg" alt="Contacts" />
                        </a>
                        <img [routerLink]="['contacts']"  src="../assets/logo.svg" id="logoArchetype" alt="Uvaga" />
                        <a [routerLink]="['contacts']" class="hvr-push">
                            <img src="../assets/contactsIcon.svg" alt="Contacts" />
                        </a>
                        </span> 
        </span>
        </span>
    </div>
</div>