import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { Project } from 'src/app/models/Project';
import {LayoutService} from 'src/app/services/layout.service';
import {CustomBreakpointNames} from 'src/app/services/breakpoints.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  desktop: any;
  mobile: any;
  projects: Project[] = [];
  loading = false;
  previouslyHoveredProject: any;

  constructor(private projectsService: ProjectsService, private layoutService: LayoutService) { }

  ngOnInit() {
    document.body.classList.add('scrollableBody');
    document.body.classList.add('projectsPage');
    this.loading = true;
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      if (this.layoutService.isBreakpointActive(CustomBreakpointNames.mobile)) {
        this.desktop = false;
        this.mobile = true;
        if (document.getElementById('desktopNav')) {
          document.getElementById('desktopNav').style.display = 'none';
          }
      } else {
        this.desktop = true;
        this.mobile = false;
        if (document.getElementById('desktopNav')) {
          document.getElementById('desktopNav').style.display = 'block';
          const desktopNav = document.getElementById('desktopNav').getBoundingClientRect();
          setTimeout(
            () => {
          document.getElementById('catalog').style.paddingTop = desktopNav.height + 'px';
            }, 1200);
        }
      }
    });

    this.projectsService.getAllProjects()
      .subscribe(projects => {
        setTimeout(
          () => {
            document.getElementById('loader').style.display = 'none';
          }, 1000);
        this.loading = false;
        this.projects = projects.sort((a, b) => {
          return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
        }).reverse();
      });
    if (this.desktop && document.getElementById('desktopNav')) {
        document.getElementById('desktopNav').style.display = 'block';
        const desktopNav = document.getElementById('desktopNav').getBoundingClientRect();
        setTimeout(
          () => {
            if (document.getElementById('catalog')) {
              document.getElementById('catalog').style.paddingTop = desktopNav.height + 'px';
            }
          }, 1200);
      }
  }
  ngOnDestroy(): void {
    document.getElementById('loader').style.display = '';
    document.body.classList.remove('projectsPage');
    document.getElementById('catalog').style.paddingTop = '';
  }

  moveStrip($event): void {
    if (this.desktop) {
    if (this.previouslyHoveredProject !== $event.target.parentNode.nextElementSibling ||
      this.previouslyHoveredProject !== $event.target.parentNode.previousElementSibling) {
      const projects = document.getElementsByClassName('project');
      this.previouslyHoveredProject = $event.target.parentNode;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < projects.length; i++) {
          projects[i].setAttribute('style', '');
      }
    }
    const eventPosition = $event.target.parentNode.getBoundingClientRect();
    const innerWidth = window.innerWidth;
    const el1 = $event.target.parentNode;
    const elementArrayNext = [el1];
    if (eventPosition.left < 0) {
      if (el1) {
        const el2 = $event.target.parentNode.nextElementSibling;
        elementArrayNext.push(el2);
        if (el2) {
          const el3 = el2.nextElementSibling;
          elementArrayNext.push(el3);
          if (el3) {
              const el4 = el3.nextElementSibling;
              elementArrayNext.push(el4);
           }
        }
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < elementArrayNext.length; i++) {
        elementArrayNext[i].setAttribute('style', 'left:0');
      }
    } else if (eventPosition.right > window.innerWidth + eventPosition.width / 3) {
      const elementArrayPrev = [el1];
      if (el1) {
        const el0 = $event.target.parentNode.previousElementSibling;
        elementArrayPrev.push(el0);
        if (el0) {
          const elm1 = el0.previousElementSibling;
          elementArrayPrev.push(elm1);
          if (elm1) {
            const elm2 = elm1.previousElementSibling;
            elementArrayPrev.push(elm2);
          }
          }
        }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < elementArrayPrev.length; i++) {
        // elementArrayPrev[i].setAttribute('style', '');
        // if (left === fivePercent) {
          elementArrayPrev[i].setAttribute('style', 'left:-15%');
        // }
      }
    }
  }
  }

  resetStrip($event): void {
    if (this.desktop) {
      this.previouslyHoveredProject = $event.target.parentNode;
    }
  }
}

